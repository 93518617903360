
























import {
  BTable, BButton, BCard, BRow, BCol, BFormDatepicker, BOverlay,
} from 'bootstrap-vue'
import dayjs from 'dayjs';

export default {
  name: "ReportingReports",
  components: {
    BTable,
    BButton,
    BOverlay
  },
  data() {
    return {
      fields: [
        'name',
        {
          key: 'download',
          label: "Download"
        }],
      items: [

      ],
      loading: false
    }
  },
  async mounted() {
    await this.getItems();
  },
  methods: {
    async getItems() {
      this.loading = true;
      const items = await this.$api.reporting.reports.GetList(1, 25);
      this.$set(this, 'items', items);
      this.loading = false;
    },
    async download(id: string) {
      this.loading = true;
      await this.$api.reporting.reports.Download(id);
      this.loading = false;
    }
  }
}
